"use client"

import { getUrl, RoutePath, Routes } from "@cyna/common/routes"
import { useI18n } from "@cyna/components/providers/ContextProvider"
import { cn } from "@cyna/components/utils"
import NextLink from "next/link"
import { ForwardedRef, forwardRef, Ref } from "react"

export type LinkProps<TRoutePath extends RoutePath> = Omit<
  Parameters<typeof NextLink>[0],
  "href" | "path" | "params" | "query"
> & {
  styless?: boolean
} & (
    | { href: string }
    | (Parameters<Routes[TRoutePath]>[1] extends undefined
        ? {
            path: TRoutePath
            query?: Parameters<Routes[TRoutePath]>[0]
          }
        : {
            path: TRoutePath
            params: Parameters<Routes[TRoutePath]>[0]
            query?: Parameters<Routes[TRoutePath]>[1]
          })
  )

const LinkRef = forwardRef(
  <TRoutePath extends RoutePath>(
    { className, styless, ...otherProps }: LinkProps<TRoutePath>,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => {
    // Using undefined to avoid creating empty `class=` HTML attribute:
    // -> smaller HTML load for each page
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing, no-undefined
    const cls = cn(styless || "underline", className) || undefined
    const [, { language }] = useI18n()
    const url =
      "href" in otherProps
        ? otherProps.href
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          getUrl(otherProps.path, otherProps)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const { params: _, path: _2, query: _3, ...cleanProps } = otherProps

    return (
      <NextLink
        ref={ref}
        className={cls}
        {...cleanProps}
        href={`/${language}${url}`}
      />
    )
  },
)

export const Link = <TRoutePath extends RoutePath>({
  ref,
  ...props
}: LinkProps<TRoutePath> & { ref?: Ref<HTMLAnchorElement> }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  <LinkRef {...props} ref={ref} />
)
