import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))

export const runOnce = (
  fn: (...args: unknown[]) => void | Promise<void>,
  timeout?: number,
): NodeJS.Timeout =>
  setTimeout(() => {
    void fn()
  }, timeout ?? 0)

export const blockClosePage = (
  confirmMessage: string,
  controller: AbortController = new AbortController(),
) => {
  const action = (e: BeforeUnloadEvent) => {
    // eslint-disable-next-line no-alert
    if (confirm(confirmMessage)) {
      //
    } else {
      e.preventDefault()
    }
  }

  window.addEventListener("beforeunload", action, {
    signal: controller.signal,
  })

  return () => {
    controller.abort()
  }
}
